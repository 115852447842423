import request from '@/utils/request1';

// v3接口

const BASE_URL = '/api';

const registerSendSmsNewApi = (data) => request({ // 手机号注册发送验证码
  url: BASE_URL + '/v3/userCenter/sms/sendCode',
  method: 'post',
  data,
});

const registerSendSmsNewApi2 = (data) => request({ // 新手机号注册发送验证码
  url: BASE_URL + '/v3/userCenter/sms/vc',
  method: 'post',
  data,
});
const checkPhoneRegisterNewApi = (data) => request({ // 检查手机号是否注册
  url: BASE_URL + '/v3/userCenter/check/checkMobileExisted',
  method: 'post',
  data,
});

const phoneSmsRegisterNewApi = (data) => request({ // 手机号验证码注册
  url: BASE_URL + '/v3/userCenter/regist/smsAndPwd',
  method: 'post',
  data,
});

const getUserVersionApi = (data, header) => request({ // 获取是否新版本
  url: BASE_URL + '/v3/userCenter/user/getUserVersion',
  method: 'post',
  data,
  header,
});

export {
  registerSendSmsNewApi,
  checkPhoneRegisterNewApi,
  phoneSmsRegisterNewApi,
  getUserVersionApi,
  registerSendSmsNewApi2,
};
